<template>
  <section class="informationModel">
    <h2 class="informationModel__title">Informacion modelo</h2>
    <div class="informationModel__contentInput">
      <label for="artisticName">Nombre artístico *</label>
      <input
        class="informationModel__input"
        type="text"
        id="artisticName"
        ref="artisticNameForm"
        placeholder="Nombre artístico"
        v-model="$store.state.user.dataUser.artisticName"
        @blur="validateString('artisticName', 'artisticNameForm', 'Nombre artístico')"
      />
    </div>
    <span ref="artisticName" class="informationModel__error"></span>
    <div class="informationModel__contentInput">
      <label for="artisticName">Calificación *</label>
      <div class="informationModel-starts">
        <span
          class="informationModel-start selectedStar"
          v-for="star in valuesStars"
          :value="star"
          :key="star"
          @click="selectStar(star)"
          @mouseout="outMouse(star)"
          @mouseover="entryMouse(star)"
        >
          ★
        </span>
      </div>
    </div>
    <span ref="qualification" class="informationModel__error"></span>
    <div class="informationModel__contentInput">
      <label for="entrydate">Fecha de ingreso</label>
      <input class="informationModel__input" type="date" id="entrydate" v-model="$store.state.user.dataUser.entrydate" />
    </div>
    <div class="informationModel__contentInput">
      <label for="monitorId">Monitor</label>
      <select class="informationModel__input" id="monitorId" v-model="$store.state.user.dataUser.monitorId">
        <option v-for="monitor in monitorOptions" :value="monitor.value" :key="monitor.value">{{ monitor.text }}</option>
      </select>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import { formatString } from "@/js/validation.js";
export default {
  data() {
    return {
      valuesStars: ["1", "2", "3", "4", "5"],
    };
  },
  computed: {
    ...mapGetters("users", ["getSelectsOptionsMonitors"]),
    monitorOptions: function() {
      return this.getSelectsOptionsMonitors;
    },
    user() {
      return this.$store.state.user.dataUser;
    },
    isEditUser() {
      return !!this.user?._id;
    },
  },
  methods: {
    selectStar(value) {
      this.$store.state.user.dataUser.qualification = value;
      this.validateQualification("qualification", value);
    },
    outMouse() {
      const starSelected = this.$store.state.user.dataUser.qualification;
      const valueToSet = starSelected > "0" ? starSelected : "0";
      this.setQualification(valueToSet);
    },
    entryMouse(value) {
      this.setQualification(value);
    },
    setQualification(value) {
      const starts = document.querySelectorAll(`.selectedStar`);
      starts.forEach((start) => {
        start.classList.remove("starActive");

        if (start.getAttribute("value") <= value) {
          start.classList.add("starActive");
        }
      });
    },
    validateString(ref, refLocal, text, data) {
      const reference = this.$refs[ref];
      const referenceLocal = this.$refs[refLocal];
      const dataToSet = data ?? event?.srcElement ?? { value: "" };

      const response = formatString(reference, text, dataToSet);

      this.updateStyles(reference, referenceLocal, response);
      return response;
    },
    validateQualification(ref, data) {
      const reference = this.$refs[ref];
      const response = !!+data;

      reference.innerText = !response ? `Ingrese la calificacion` : "";

      this.updateStyles(reference, "", response);
      return response;
    },
    updateStyles(reference, referenceLocal, response) {
      reference.style.display = response ? "none" : "block";
      referenceLocal && (referenceLocal.style["border-color"] = response ? "#d8d8d8" : "#bd0909");
    },
    verifyNext() {
      const { artisticName, qualification } = this.$store.state.user.dataUser;
      const nameForm = this.validateString("artisticName", "artisticNameForm", "Nombre artístico", { value: artisticName });
      const hasQualification = this.validateQualification("qualification", qualification);

      return nameForm && hasQualification;
    },
  },
  mounted() {
    this.outMouse();

    if (this.isEditUser) {
      this.verifyNext();
    }
  },
};
</script>

<style lang="scss">
.informationModel {
  @include Flex(column, flex-start, flex-start);
  width: 100%;
  gap: $mpadding;
  &__title {
    font-weight: lighter;
    font-size: 24px;
  }
  &__contentInput {
    width: 100%;
  }
  &__input {
    width: 100%;
    height: 40px;
    border: 1px solid $alto;
    border-radius: 6px;
    padding: 5px;
    &:focus {
      outline: none;
    }
  }
  &-starts {
    font-size: 35px;
    color: #ccc;
    margin-top: -10px;
  }
  .starActive {
    transition: 0.3s ease-in-out;
    color: $primary-color;
  }
  &__error {
    display: none;
    margin-top: -10px;
    font-weight: 600;
    font-size: 0.8em;
    color: $primary-color;
  }
}
</style>
